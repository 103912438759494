<!-- 文件中文名: 商品详情 -->
<template>
  <div class="GoodsDetail app-container">
    <div class="details">
      <div class="details-letf">
        <!--大图-->
        <div
          :class="{ 'image-border': imageBorder }"
          class="letf-head"
          @mouseenter="imageBorder = true"
          @mouseleave="imageBorder = false"
        >
          <el-image
            :preview-src-list="iamgeList"
            :src="imgsrc"
            fit="contain"
            style="width: 100%; height: 100%"
          >
          </el-image>
        </div>
        <!--小图展示-->
        <div class="letf-center-img" v-for="img in iamgeList" :key="img">
          <el-image
            :preview-src-list="iamgeList"
            :src="img"
            fit="contain"
            style="width: 100%; height: 100%"
          >
          </el-image>
        </div>
        <!-- 防止塌陷 -->
        <div style="clear: both"></div>
      </div>
      <div class="details-right">
        <div style="text-align: left; padding: 0 10px; box-sizing: border-box">
          <el-form>
            <h2>{{ $t(goodDetail.goodsName) }}</h2>
            <p>{{ $t('Product.Code') + ':' }} {{ goodDetail.goodsCode }}</p>
            <p>{{ $t('Spot.goods') + ':' }} {{ goodDetail.validQty }}</p>
            <div v-if="1 === 2">
              <p v-show="goodDetail.isDoing === 'true'">
                {{ $t('pd.price') + ':' }} {{ Number(goodDetail.proPriceB) >= 999999 ? '--' : goodDetail.proPriceB }}{{ $symbol() }}
              </p>
              <p v-show="goodDetail.isDoing === 'true' && userCode">
                <!--              {{ goodDetail.proPrice }}W$ -->
                {{ Number(goodDetail.proPv) >= 999999 ? '--' : goodDetail.proPv }}PV
              </p>
              <p v-show="goodDetail.isDoing === 'true'">
                {{ $t('busi.direct.sale_points') + ':' }}{{ goodDetail.proF000 }}
              </p>
              <p v-show="goodDetail.isDoing === 'false'">
                {{ $t('pd.price') + ':' }} {{ Number(goodDetail.proPriceB) >= 999999 ? '--' : goodDetail.proPriceB }}{{ $symbol() }}
              </p>
              <p v-show="goodDetail.isDoing === 'false' && userCode">
                <!--              {{ goodDetail.standardPrice }}W$ -->
                {{ Number(goodDetail.proPv) >= 999999 ? '--' : goodDetail.proPv }}PV
              </p>
            </div>
            <p >
              <!--              首购价格-->
              {{ $t('Initial.purchase.price') + ':' }} {{ Number(goodDetail.standardPriceB) >= 999999 ? '--' : goodDetail.standardPriceB }}{{ $symbol() }}
<!--              {{ $t('Initial.purchase.price') + ':' }} {{ Number(goodDetail.standardPrice) >= 999999 ? '&#45;&#45;' : goodDetail.standardPrice }}-->
            </p>
            <p >
              <!--              首购Pv-->
              <span  v-show="userCode">
              {{ $t('busi.direct.first.purchase') + 'PV' + ':' }} {{ Number(goodDetail.standardPv) >= 999999 ? '--' : goodDetail.standardPv }}PV
              </span>
            </p>
            <p >
            <!--             重消价格 -->
              {{ $t('Cancellation.price') + ':' }} {{ Number(goodDetail.proPriceB) >= 999999 ? '--' : goodDetail.proPriceB }}{{ $symbol() }}
            </p>
            <p >
              <!--              {{ goodDetail.proPrice }}W$ -->
              <span  v-show="userCode">
                {{ $t('pmProduct.controlRepurchase') + 'PV' + ':' }} {{ Number(goodDetail.proPv) >= 999999 ? '--' : goodDetail.proPv }}PV
              </span>
            </p>
            <p >
              {{ $t('pmProduct.controlRepurchase') + $t('busi.direct.sale_points')  + ':' }}{{ goodDetail.proF000 >= 999999 ? '--' : goodDetail.proF000 }}
            </p>

            <!--            <p>{{$t('ruble.price')+(':')}} 500RUB这个还没有完成</p>-->
            <p style="margin-bottom: 5px">{{ $t('biBarCode.quantity') }}</p>
            <el-input v-model.number="num"></el-input>
            <el-button
              :class="{ 'btn-color-green': MouseColor }"
              class="btn-color"
              @click="clickPurchase"
              @mouseenter.native="MouseColor = true"
              @mouseleave.native="MouseColor = false"
            >
              {{ $t('common.buy') }}
            </el-button>
          </el-form>
        </div>
      </div>
    </div>
    <div class="letf-center-p">
      <!-- 描述按钮-->
      <p v-show="this.companyCode === 'LTV' || this.language === 'LTV'" class="letf-center-one">{{ $t('BiGoods.productdesc') }}</p>
      <!-- 下载证书按钮 -->
      <p v-if="credentials" class="letf-center-file" @click="fileBtn">
        {{ $t('Download_certificate') }}
      </p>
      <p class="letf-center-two"></p>
    </div>
    <!-- 商品描述详情 - PC-->
    <div class="pc-details" style="word-wrap: break-word">
      <el-form label-width="auto">
        <el-form-item>
          <div style="text-align: left" v-html="this.shortDesc">
            {{ this.shortDesc }}
          </div>
        </el-form-item>
        <el-form-item>
          <div style="text-align: left" v-html="this.productdesc">
            {{ this.productdesc }}
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- 商品描述详情 - 移动-->
    <div class="yidong-details" style="word-wrap: break-word">
      <div>
        <div style="text-align: left" v-html="this.shortDesc">
          {{ this.shortDesc }}
        </div>
        <div style="text-align: left" v-html="this.productdesc">
          {{ this.productdesc }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMessagerById } from '@/api/rus/api'
export default {
  name: 'GoodsDetail',
  data() {
    return {
      imgsrc: '',
      iamgeList: [],
      purchaseInfo: false,
      num: 1,
      shortDesc: false,
      productdesc: false,
      btnBlack: false,
      imageBorder: false,
      MouseColor: false,
      goodsId: '',
      companyGoodsId: '',
      goodsCode: '',
      doLoading: false,
      credentials: '',
      goodDetail: {
        num: 1,
        chongxiao: '',
        companyCode: '',
        giftGoodsCode: '',
        goodsCode: '',
        goodsName: '',
        id: '',
        isPack: '',
        kindCode: '',
        kindName: '',
        photoName: '',
        pictureUrl: '',
        proPrice: '',
        proPv: '',
        quantity: '',
        seqNo: '',
        shengji: '',
        shortDesc: '',
        specification: '',
        specificationName: '',
        standardF000: '',
        standardPriceB: '',
        standardPv: '',
        status: '',
        stockTip: '',
        validQty: '',
        isDoing: '',
      },
    }
  },
  computed: {
    ...mapGetters(['shoppingList', 'language', 'companyCode', 'ckCode', 'userCode']),
  },
  watch: {
    $route() {
      this.goodDetail = this.$route.query
      this.companyGoodsId = this.$route.query.companyGoodsId
      this.goodsId = this.$route.query.id
      this.goodsCode = this.$route.query.goodscode
      if (this.companyGoodsId || this.goodsId) {
        this.getList()
      } else {
        this.$router.go(-1)
      }
    },
    language() {
      this.getList()
    },
  },
  created() {
    this.goodDetail = this.$route.query
    this.companyGoodsId = this.$route.query.companyGoodsId
    this.goodsId = this.$route.query.id
    this.goodsCode = this.$route.query.goodsCode
    if (this.companyGoodsId || this.goodsId) {
      this.getList()
    } else {
      this.$router.go(-1)
    }
  },
  methods: {
    getList() {
      if (this.goodDetail.menpiao === 'true' || this.goodDetail.tuijian === 'true') {
        this.doLoading = true
        getMessagerById({ goodsCode: this.goodsCode, companyCode: this.companyCode || this.ckCode })
          .then((res) => {
            this.doLoading = false
            this.iamgeList = res.data.imgsrc.split(';')
            this.imgsrc = this.iamgeList[0]
            this.credentials = res.data.credentials
            this.shortDesc = res.data.shortDesc
            this.productdesc = res.data.productdesc.replace(/&nbsp;/g, ' ')
          })
          .catch((err) => {
            console.error(err)
            this.doLoading = false
          })
      } else {
        this.doLoading = true
        if (this.companyGoodsId) {
          getMessagerById({id: this.companyGoodsId, companyCode: this.companyCode})
            .then((res) => {
              this.doLoading = false
              this.iamgeList = res.data.imgsrc.split(';')
              this.imgsrc = this.iamgeList[0]
              this.credentials = res.data.credentials
              this.shortDesc = res.data.shortDesc
              this.productdesc = res.data.productdesc.replace(/&nbsp;/g, ' ')
            })
            .catch((err) => {
              console.error(err)
              this.doLoading = false
            })
        } else {
          getMessagerById({goodsCode: this.goodsCode, companyCode: this.companyCode || this.ckCode})
            .then((res) => {
              this.doLoading = false
              this.iamgeList = res.data.imgsrc.split(';')
              this.imgsrc = this.iamgeList[0]
              this.credentials = res.data.credentials
              this.shortDesc = res.data.shortDesc
              this.productdesc = res.data.productdesc.replace(/&nbsp;/g, ' ')
            })
            .catch((err) => {
              console.error(err)
              this.doLoading = false
            })
        }
      }
    },
    // 关闭信息
    clickPBlack() {
      this.purchaseInfo = false
    },
    // 购买
    async clickPurchase() {
      if (this.num <= 0) {
        this.$message.error(this.$t('leastInputANumber'))
        this.purchaseInfo = false
        return false
      }
      let flag = ''
      if (this.goodDetail.isSpot == 0) {
        flag = this.$t('goods.stock.NoSpot') + '!'
      }
      const h = this.$createElement
      this.$confirm(this.$t('common.tips'), {
        title: this.$t('common.tips'),
        message: h('div', [
          h('p', `${flag}`),
          h('p', this.$t('AddGoodsToShoppingCart').toString()),
        ]),
        confirmButtonText: this.$t('Confirm.Order'),
        cancelButtonText: this.$t('operation.button.cancel'),
        customClass: 'msgBox',
        type: 'warning',
      })
        .then(() => {
          this.goodDetail.num = this.num
          this.$store.dispatch('shopping/addShoppings', this.goodDetail)
          this.purchaseInfo = true
          this.$message({
            type: 'success',
            message: this.$t('bigoods.addcart.success'),
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('status.check.canceled') + '!',
          })
        })
    },
    // 下载证书
    fileBtn() {
      let a = document.createElement('a')
      let event = new MouseEvent('click')
      a.href = this.credentials
      a.dispatchEvent(event)
    },
  }
}
</script>

<style lang="scss" scoped>
.purchase-info {
  text-align: left;
  color: #3c763d;
  background-color: #dff0d8;
  margin: 0;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid transparent;
  border-radius: 4px;
}
.p-color {
  font-size: 16px;
  color: #bed8b9;
  cursor: pointer;
}
.p-black {
  color: black;
}
.details {
  width: 100%;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  margin-top: 10px;
}
.details-letf {
  flex: 1;
}
.letf-head {
  padding: 2px 5px;
  height: 288px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgb(153 157 163 / 5%),
    0 5px 10px rgb(153 157 163 / 5%);
  cursor: pointer;
  margin-bottom: 5px;
}
.letf-center-img {
  float: left;
  margin-right: 10px;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgb(153 157 163 / 5%),
    0 5px 10px rgb(153 157 163 / 5%);
}
.letf-center-p {
  display: flex;
}
.letf-center-one {
  background-color: #fff;
  border: 1px solid #ddd;
  width: 80px;
  padding: 10px;
  color: #ff5f00;
  border-bottom-color: transparent;
  margin-bottom: 0;
  display: inline-block;
  border-radius: 5%;
}
.letf-center-file {
  cursor: pointer;
  background-color: #f1f1f1;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  width: 80px;
  padding: 10px;
  color: #21a2f1;
  border-bottom-color: transparent;
  margin-bottom: 0;
  display: inline-block;
  border-radius: 5%;
}
.letf-center-two {
  flex: 1;
  margin-bottom: 0;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}
.details-right {
  width: 450px;
  margin: 0 10px;
  //flex: 1;
}
.image-border {
  border: 1px solid #337ab7;
}
.btn-color {
  width: 100%;
  padding: 16px;
  background: #ff5f00;
  border: 0 white;
  margin-top: 10px;
  cursor: pointer;
  color: #fff1ff;
}
.btn-color-green {
  background: #08a652;
}
.pc-details {
  display: block;
}
.yidong-details {
  display: none;
}
@media screen and (max-width: 960px) {
  .pc-details {
    display: none;
  }
  .yidong-details {
    display: block;
  }
}

::v-deep p {
  margin: 0;
}
</style>
